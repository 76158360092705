@import "../../../css/variables";

.nieuws_module {
  padding: 120px 0;

  &__titel {
    margin-bottom: 10px;

    h2, h3, h4, p {
      font-size: 5.5rem;
      line-height: 1.2;
      color: $color-primary;
      text-align: center;
      margin: 0;
    }
  }
  &__intro_text {
    color: $color-primary;
    padding-bottom: 50px;
    text-align: center;
  }

  &__categorieen {
    @import "../css/categorieen-list";
  }

  .nieuws_item {
    $item: &;
  
    &:nth-child(even) {
      background: #fff;

      &:hover {
        #{$item}__item_titel {
          color: #fff;
        }
      }
    }
      
    @media (min-width:768px) and (max-width:992px){
      &:nth-child(8n-6),&:nth-child(8n-5), &:nth-child(8n-2), &:nth-child(8n-1) {
        background: #fff;

        &:hover {
          #{$item}__item_titel {
            color: #fff;
          }
        }
      }
    }

    @media (max-width:768px){
      
      &:nth-child(even) {
        background: #fff;

        &:hover {
          #{$item}__item_titel {
            color: #fff;
          }
        }
      }
    }
  }

}
@import "../css/list";
@import "../css/item";