ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;

    li {
        border: 1px solid $color-primary;
        margin-right: 20px;
        margin-bottom: 15px;

        @media(max-width: 768px){
            margin-right: 10px;
            margin-bottom: 10px;
        }

        &.active a {
            pointer-events: none;
            background: $color-primary;
            color: #FFF;
        }

        a {
            padding: 15px 20px;
            display: block;
            font-size: 12px;
            line-height: 12px;
            text-transform: uppercase;
            color: $color-primary;
            background: #fff;
            text-decoration: none;
            cursor: pointer;

            &:hover, &:focus, &:active {
                background: $color-primary;
                color: #FFF;
                text-decoration: none;
            }
        }
    }
}